<template>
  <div class="app">
    <SideBar
      v-if="isAuthenticated"
      :isExpanded="isExpanded"
      @toggleExpanded="isExpanded = !isExpanded"
    />
    <div
      class="content-view"
      :style="{ 'margin-left': isExpanded ? '250px' : 'calc(2rem + 32px)' }"
    >
      <NavBar v-if="isAuthenticated" />
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import SideBar from "@/components/sidebar/SideBar.vue";
import NavBar from "@/components/navbar/NavBar.vue";
import "@fortawesome/fontawesome-free/css/all.min.css";

const isAuthenticated = ref(false);
const isExpanded = ref(false);

onMounted(async () => {
  isAuthenticated.value = !!localStorage.getItem("user_id");
});
</script>

<style lang="scss" src="./styles/app.scss"></style>
